package games.mythical.saga.watcher.sagawatcher.frontend.layout

import csstype.px

object Sizes {
    object Header {
        val Height = 64.px
    }

    object Sidebar {
        val Width = 135.px
    }
}
