package games.mythical.saga.watcher.sagawatcher.frontend

import browser.window
import csstype.Auto.auto
import csstype.Display
import csstype.GridTemplateAreas
import csstype.array
import games.mythical.saga.watcher.sagawatcher.frontend.component.*
import games.mythical.saga.watcher.sagawatcher.frontend.entity.Application
import games.mythical.saga.watcher.sagawatcher.frontend.entity.Applications
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Area
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Header
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Sizes
import games.mythical.saga.watcher.sagawatcher.frontend.layout.ThemeModule
import games.mythical.saga.watcher.sagawatcher.frontend.util.WatcherApi
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import mui.material.useMediaQuery
import mui.system.Box
import mui.system.sx
import react.*
import react.dom.client.createRoot
import react.router.dom.HashRouter
import web.url.URLSearchParams

val mainScope = MainScope()

external interface ApplicationProps : PropsWithChildren {
    var applications: Applications
}

external interface AppVersionProps : PropsWithChildren {
    var application: games.mythical.saga.watcher.sagawatcher.model.Application
}

fun main() {
    val container = document.getElementById("root") ?: error("Couldn't find root container!")

    val searchParams = URLSearchParams(window.location.search)
    if (searchParams.has("code")) {
        mainScope.launch {
            WatcherApi.fetchGHSession(searchParams.get("code")!!)
            val redirect = localStorage.getItem("gh-redirect") ?: window.location.origin
            localStorage.removeItem("gh-redirect")
            window.location.href = redirect
        }
    } else {
        createRoot(container).render(App.create())
    }
}

var App = FC<Props> {
    val mobileMode = useMediaQuery("(max-width:960px)")
    var applications: Applications by useState(emptyList())
    var loading by useState(true)

    useEffectOnce {
        mainScope.launch {
            val appVersions = WatcherApi.fetchApplications()

            applications = appVersions.applications.keys.sorted().map { key ->
                Application(key, appVersions.applications[key]!!, AppVersionModule)
            }

            if (!WatcherApi.hasGHSession) {
                WatcherApi.fetchGHSession()
            }

            loading = false
        }
    }

    if (loading) {
        return@FC
    }

    HashRouter {
        ApplicationsModule {
            this.applications = applications

            ThemeModule {
                Box {
                    sx {
                        display = Display.grid
                        gridTemplateRows = array(
                            Sizes.Header.Height,
                            auto,
                        )
                        gridTemplateColumns = array(
                            Sizes.Sidebar.Width, auto,
                        )
                        gridTemplateAreas = GridTemplateAreas(
                            arrayOf(Area.Header, Area.Header),
                            if (mobileMode)
                                arrayOf(Area.Content, Area.Content)
                            else
                                arrayOf(Area.Sidebar, Area.Content),
                        )
                    }

                    Header()
                    if (mobileMode) {
                        Menu {
                            this.applications = applications
                        }
                    } else {
                        Sidebar {
                            this.applications = applications
                        }
                    }
                    Content {
                        this.applications = applications
                    }
                }
            }
        }
    }
}