package games.mythical.saga.watcher.sagawatcher.frontend.component

import csstype.px
import games.mythical.saga.watcher.sagawatcher.frontend.ApplicationProps
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Area
import mui.material.Typography
import mui.system.Box
import mui.system.sx
import react.FC
import react.create
import react.dom.html.ReactHTML.main
import react.router.Outlet
import react.router.Route
import react.router.Routes

private val DEFAULT_PADDING = 30.px

val Content = FC<ApplicationProps> { props ->
    Routes {
        Route {
            path = "/"
            element = Box.create {
                component = main
                sx {
                    gridArea = Area.Content
                    padding = DEFAULT_PADDING
                }

                Outlet()
            }

            Route {
                path = "/"
                element = Dashboard.create { applications = props.applications }
            }

            Route {
                path = "/release-notes"
                element = GithubLogin.create { ReleaseNotes { } }
            }

            Route {
                path = "/changelog"
                element = GithubLogin.create { ReleaseNotes { } }
            }

            props.applications.forEachIndexed { i, (key, value, component) ->
                val appVersionModule = component.create {
                    application = value
                }

                Route {
                    index = i == 0
                    path = key
                    element = appVersionModule
                }
            }

            Route {
                path = "*"
                element = Typography.create { +"404 Page Not Found" }
            }
        }
    }
}
