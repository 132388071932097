package games.mythical.saga.watcher.sagawatcher.frontend.util

import browser.window
import games.mythical.saga.watcher.sagawatcher.model.AppVersions
import games.mythical.saga.watcher.sagawatcher.model.GHSession
import games.mythical.saga.watcher.sagawatcher.model.SagaGitCommit
import kotlinx.coroutines.await
import kotlinx.serialization.decodeFromString
import kotlinx.serialization.json.Json
import org.w3c.fetch.INCLUDE
import org.w3c.fetch.RequestCredentials
import org.w3c.fetch.RequestInit

external fun encodeURIComponent(text: String): String

class WatcherApi {
    companion object {
        var hasGHSession = false
        var ghClientId: String = ""
        var ghPatEnabled: Boolean = false

        private suspend inline fun <reified T> fetch(path: String, params: Map<String, String> = mapOf()): T {
            val queryString = if (params.isEmpty()) "" else "?" + params.map { "${it.key}=${encodeURIComponent(it.value)}" }.joinToString("&")

            val response = window.fetch(
                "${process.env.WATCHER_URL}/${path}${queryString}",
                RequestInit(credentials = RequestCredentials.INCLUDE)
            ).await().text().await()

            return Json.decodeFromString(response)
        }

        suspend fun fetchApplications(): AppVersions {
            return fetch("services")
        }

        suspend fun fetchCommits(repo: String, branch: String, fromTimestamp: Long? = null): List<SagaGitCommit> {
            val params = mutableMapOf(
                "repo" to repo,
                "branch" to branch
            )

            if (fromTimestamp != null) {
                params["from"] = fromTimestamp.toString()
            }

            return fetch("gh/commits", params)
        }

        suspend fun fetchGHSession(code: String? = null): GHSession {
            val params = if (code == null) emptyMap() else mapOf("code" to code)
            val session = fetch<GHSession>("gh/session", params)
            hasGHSession = session.id.isNotEmpty()
            ghClientId = session.clientId
            ghPatEnabled = session.patEnabled
            return session
        }
    }
}