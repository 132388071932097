package games.mythical.saga.watcher.sagawatcher.frontend.component

import csstype.None
import csstype.TextAlign
import csstype.px
import emotion.react.css
import games.mythical.saga.watcher.sagawatcher.frontend.mainScope
import games.mythical.saga.watcher.sagawatcher.frontend.util.WatcherApi
import games.mythical.saga.watcher.sagawatcher.model.SagaGitCommit
import kotlinx.coroutines.launch
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.b
import react.dom.html.ReactHTML.br
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.pre
import react.dom.html.ReactHTML.ul
import react.router.dom.useSearchParams
import kotlin.js.Date

private val envBranches = mapOf(
    "dev-saga" to "develop",
    "qa-saga" to "main",
    "sandbox-saga" to "sandbox",
    "prod-saga" to "release"
)

private val envNames = mapOf(
    "dev-saga" to "Dev",
    "qa-saga" to "QA",
    "sandbox-saga" to "Sandbox",
    "prod-saga" to "Prod"
)

val ReleaseNotes = FC<Props> { _ ->
    val applications = useContext(ApplicationsContext)
    val searchParams by useSearchParams()
    var markdownNotes by useState<String?>(null)
    var listNotes by useState<List<ServiceReleaseNotes>>(emptyList())
    var loadingMessage by useState("")
    var viewMarkdown by useState(false)

    val sourceEnvName = searchParams.get("source") ?: "dev-saga"
    val targetEnvName = searchParams.get("target") ?: "qa-saga"

    useEffectOnce {
        mainScope.launch {

            val allNotes = mutableMapOf<String, List<String>>()

            for (app in applications) {
                var targetDeployedSha1: String? = null

                if (app.app.environments.isNotEmpty()) {
                    val sourceEnv = app.app.environments[sourceEnvName] ?: continue
                    val targetEnv = app.app.environments[targetEnvName] ?: continue

                    if (sourceEnv.version == targetEnv.version) {
                        continue
                    }

                    targetDeployedSha1 = targetEnv.version.split('-').last()
                }

                if (app.app.name == "partner-wallet" || app.app.name == "player-wallet") {
                    continue
                }

                val appName = if (app.app.name == "publisher-wallet") "wallet-service" else app.app.name
                loadingMessage = appName

                val repoName = app.app.githubUrl.split('/').last()
                var changeLogEndReached = false
                var oldestSourceCommitTimestamp: Long? = null
                var oldestTargetCommitTimestamp: Long? = null
                val notes = mutableListOf<String>()
                val targetCommitsMap = mutableMapOf<String, SagaGitCommit>()

                while (!changeLogEndReached) {
                    val sourceCommits = WatcherApi.fetchCommits(repoName, envBranches[sourceEnvName]!!, oldestSourceCommitTimestamp)
                    val targetCommits = WatcherApi.fetchCommits(repoName, envBranches[targetEnvName]!!, oldestTargetCommitTimestamp)

                    targetCommitsMap.putAll(targetCommits.associateBy { it.sha1 })
                    for (commit in sourceCommits) {
                        val targetCommit = targetCommitsMap[commit.sha1]
                        if (targetCommit != null &&
                            (targetDeployedSha1 == null || targetCommit.sha1.startsWith(targetDeployedSha1))
                        ) {
                            changeLogEndReached = true
                            break
                        }

                        if (targetCommit == null) {
                            notes.add(prettifyMessage(commit.message))
                        }
                    }

                    if (sourceCommits.isEmpty() || targetCommits.isEmpty()) {
                        break
                    }

                    oldestSourceCommitTimestamp = Date(sourceCommits.last().committedAt).getTime().toLong() - 500L
                    oldestTargetCommitTimestamp = Date(targetCommits.last().committedAt).getTime().toLong() - 500L
                }

                allNotes[appName] = notes
            }

            var stringNotes = ""
            val newListNotes = mutableListOf<ServiceReleaseNotes>()
            for ((appName, notes) in allNotes.entries) {
                if (notes.isEmpty()) {
                    continue
                }

                stringNotes += "*$appName*\n${notes.joinToString("\n") { "* $it" }}\n\n"
                newListNotes.add(ServiceReleaseNotes(appName, notes))
            }

            markdownNotes = stringNotes
            listNotes = newListNotes
        }
    }

    if (markdownNotes == null) {
        Box {
            sx {
                textAlign = TextAlign.center
            }

            CircularProgress { }

            Box {
                +loadingMessage
            }
        }
    } else {
        Box {
            Typography {
                variant = TypographyVariant.h5

                +"Changelog - ${envNames[sourceEnvName]} to ${envNames[targetEnvName]}"
            }
        }

        Box {
            sx {
                userSelect = None.none
            }

            FormControlLabel {
                label = ReactNode("View Markdown")
                control = Switch.create {
                    checked = viewMarkdown
                    onChange = { _, value -> viewMarkdown = value }
                }
            }
        }

        if (viewMarkdown) {
            pre {
                +markdownNotes!!
            }
        } else {
            for (service in listNotes) {
                b {
                    +service.name
                }
                br { }
                ul {
                    css {
                        margin = 0.px
                    }

                    for (note in service.notes) {
                        li {
                            +note
                        }
                    }
                }
                br { }
            }
        }
    }
}

private fun prettifyMessage(message: String): String {
    val lines = message.split('\n')
    return lines[0].replace(Regex("\\(#\\d+\\)"), "").trim()
}

private data class ServiceReleaseNotes(
    val name: String,
    val notes: List<String>
)