package games.mythical.saga.watcher.sagawatcher.frontend.component


import csstype.Color
import csstype.None.none
import emotion.react.css
import games.mythical.saga.watcher.sagawatcher.frontend.ApplicationProps
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Area
import games.mythical.saga.watcher.sagawatcher.frontend.layout.Sizes
import mui.material.*
import mui.material.DrawerAnchor.left
import mui.material.DrawerVariant.permanent
import mui.system.Box
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.html.ReactHTML.nav
import react.router.dom.NavLink
import react.router.useLocation

val Sidebar = FC<ApplicationProps> { props ->
    val location = useLocation()
    val lastPathname = location.pathname.substringAfterLast("/")

    Box {
        component = nav
        sx { gridArea = Area.Sidebar }

        Drawer {
            variant = permanent
            anchor = left

            Box {
                Toolbar()

                List {
                    sx { width = Sizes.Sidebar.Width }

                    NavLink {
                        to = "/"

                        css {
                            textDecoration = none
                            color = Color.currentcolor
                        }

                        ListItemButton {
                            selected = location.pathname == "/"

                            ListItemText {
                                primary = ReactNode("Dashboard")
                            }
                        }
                    }

                    for ((key, app) in props.applications) {
                        NavLink {
                            to = key

                            css {
                                textDecoration = none
                                color = Color.currentcolor
                            }

                            ListItemButton {
                                selected = lastPathname == key

                                ListItemText {
                                    primary = ReactNode(app.name)
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
