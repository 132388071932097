package games.mythical.saga.watcher.sagawatcher.frontend.component

import browser.window
import games.mythical.saga.watcher.sagawatcher.frontend.util.WatcherApi
import kotlinx.browser.localStorage
import mui.icons.material.GitHub
import mui.material.Button
import mui.material.ButtonVariant
import react.FC
import react.PropsWithChildren
import react.create
import kotlin.random.Random

private fun generateStateParam(): String {
    val chars: List<Char> = ('a'..'z') + ('A'..'Z') + ('0'..'9')
    return (1..10).map { Random.nextInt(0, chars.size).let { chars[it] } }.joinToString("")
}

val GithubLogin = FC<PropsWithChildren> { props ->


    if (WatcherApi.hasGHSession || WatcherApi.ghPatEnabled) {
        +props.children
    } else if (WatcherApi.ghClientId.isNotEmpty()) {
        Button {
            variant = ButtonVariant.outlined
            endIcon = GitHub.create()

            onClick = { _ ->
                run {
                    localStorage.setItem("gh-redirect", window.location.href)
                    val stateParam = generateStateParam()
                    window.location.href =
                        "https://github.com/login/oauth/authorize?scope=repo&client_id=${WatcherApi.ghClientId}&state=$stateParam"
                }
            }

            +"Log in with GitHub"
        }
    } else {
        +"GitHub auth is not configured"
    }
}