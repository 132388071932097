package games.mythical.saga.watcher.sagawatcher.model

import kotlinx.serialization.Serializable

@Serializable
data class SagaGitCommit(
    val message: String,
    val committedBy: String,
    val committedAt: String,
    val sha1: String,
    val githubUrl: String
)