package games.mythical.saga.watcher.sagawatcher.frontend.component

import browser.window
import csstype.px
import games.mythical.saga.watcher.sagawatcher.frontend.AppVersionProps
import mui.icons.material.GitHub
import mui.material.*
import mui.material.styles.Theme
import mui.material.styles.useTheme
import mui.system.sx
import react.FC
import react.ReactNode
import react.dom.aria.ariaLabel
import react.router.dom.useSearchParams
import web.url.URLSearchParams

val AppVersionModule = FC<AppVersionProps> { props ->
    val theme = useTheme<Theme>()

    var searchParams by useSearchParams()
    val activeTab = searchParams.get("tab") ?: (if (props.application.environments.isEmpty()) "commits" else "k8s")

    Typography {
        sx {
            padding = theme.spacing(1)
        }

        +props.application.name

        Tooltip {
            title = ReactNode("View ${props.application.name} Sources")

            IconButton {
                ariaLabel = "${props.application.name}-github-link"
                size = Size.large
                onClick = {
                    window.open(props.application.githubUrl)
                }

                GitHub()
            }
        }
    }

    Tabs {
        value = activeTab
        onChange = { _, tab -> run { searchParams.set("tab", tab); searchParams = URLSearchParams(searchParams) } }

        if (props.application.environments.isNotEmpty()) {
            Tab {
                value = "k8s"
                label = ReactNode("k8s")
            }
        }
        Tab {
            value = "commits"
            label = ReactNode("Commits")
        }
    }

    Divider { }

    Box {
        sx {
            marginTop = 10.px
        }

        if (activeTab == "k8s") {
            props.application.environments.values.forEach { env ->
                Divider {
                    sx {
                        padding = theme.spacing(1)
                    }
                }

                DeploymentCard {
                    application = props.application
                    this.env = env
                }
            }
        } else if (activeTab == "commits") {
            GithubLogin {
                ServiceCommits {
                    app = props.application
                }
            }
        }
    }
}