package games.mythical.saga.watcher.sagawatcher.frontend.layout

import csstype.integer
import csstype.number
import kotlinx.browser.localStorage
import mui.icons.material.Brightness4
import mui.icons.material.Brightness7
import mui.material.*
import mui.material.styles.TypographyVariant.h6
import mui.system.sx
import react.*
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML.div

val Header = FC<Props> {
    var theme by useContext(ThemeContext)

    useEffect {
        theme = when(localStorage.getItem("theme")) {
            null -> theme
            "light" -> Themes.Light
            "dark" -> Themes.Dark
            else -> throw Exception("invalid theme")
        }
    }

    AppBar {
        position = AppBarPosition.fixed
        sx {
            gridArea = Area.Header
            zIndex = integer(1_500)
        }

        Toolbar {
            Typography {
                sx { flexGrow = number(1.0) }
                variant = h6
                noWrap = true
                component = div

                +"Saga Watcher"
            }

            Tooltip {
                title = ReactNode("Theme")

                Switch {
                    icon = Brightness7.create()
                    checkedIcon = Brightness4.create()
                    checked = theme == Themes.Dark
                    ariaLabel = "theme"

                    onChange = { _, checked ->
                        theme = if (checked) {
                            localStorage.setItem("theme", "dark")
                            Themes.Dark
                        } else {
                            localStorage.setItem("theme", "light")
                            Themes.Light
                        }

                    }
                }
            }
        }
    }
}
