package games.mythical.saga.watcher.sagawatcher.frontend.component

import csstype.None.none
import csstype.px
import emotion.react.css
import games.mythical.saga.watcher.sagawatcher.frontend.entity.Applications
import games.mythical.saga.watcher.sagawatcher.frontend.util.xs
import games.mythical.saga.watcher.sagawatcher.model.Application
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.dom.useSearchParams
import web.url.URLSearchParams

private val envNames = listOf("dev-saga", "qa-saga", "sandbox-saga", "prod-saga")

private fun appHasDiff(app: Application, envIndex: Int): Boolean {
    val env = app.environments[envNames[envIndex]]
    val nextEnv = app.environments[envNames[envIndex + 1]]
    return env != null && nextEnv != null && env.version != nextEnv.version
}

external interface DashboardProps : Props {
    var applications: Applications
}

val Dashboard = FC<DashboardProps> { props ->
    var searchParams by useSearchParams()

    val showDiffOnly = searchParams.get("diffOnly")?.toBoolean() ?: false
    val diffTargetEnvIndex = (searchParams.get("diffEnv") ?: "1").toIntOrNull() ?: 1

    val envNamesSlice = useMemo(showDiffOnly, diffTargetEnvIndex) {
        if (showDiffOnly) {
            IntRange(diffTargetEnvIndex - 1, diffTargetEnvIndex)
        } else {
            envNames.indices
        }
    }

    val applications = useMemo(props.applications, showDiffOnly, envNamesSlice) {
        if (showDiffOnly) {
            props.applications.filter {
                var hasDiff = false

                for (i in envNamesSlice.first until envNamesSlice.last) {
                    if (appHasDiff(it.app, i)) {
                        hasDiff = true
                        break
                    }
                }

                hasDiff
            }
        } else {
            props.applications
        }
    }

    Grid {
        container = true
        spacing = responsive(2)

        Grid {
            item = true

            FormControlLabel {
                label = ReactNode("Different envs only")
                control = Switch.create {
                    checked = showDiffOnly
                    onChange = { _, value -> run {
                        searchParams.set("diffOnly", value.toString())
                        searchParams = URLSearchParams(searchParams)
                    } }
                }
            }
        }

        if (showDiffOnly) {
            Grid {
                item = true

                FormControl {
                    variant = FormControlVariant.standard
                    sx {
                        minWidth = 200.px
                        marginTop = (-14).px
                    }

                    InputLabel {
                        +"Envs"
                    }

                    Select {
                        value = diffTargetEnvIndex.toString()
                        onChange = { event, _ -> run {
                            searchParams.set("diffEnv", event.target.value)
                            searchParams = URLSearchParams(searchParams)
                        } }

                        MenuItem {
                            value = "1"
                            +"Dev/QA"
                        }

                        MenuItem {
                            value = "2"
                            +"QA/Sandbox"
                        }

                        MenuItem {
                            value = "3"
                            +"Sandbox/Production"
                        }
                    }
                }
            }

            Grid {
                item = true

                react.router.dom.Link {
                    css {
                        textDecoration = none
                    }

                    to = "/changelog?source=${envNames[envNamesSlice.first]}&target=${envNames[envNamesSlice.last]}"

                    Button {
                        variant = ButtonVariant.contained
                        +"Generate Changelog"
                    }
                }
            }
        }
    }

    for (app in applications) {
        if (app.app.environments.isEmpty()) {
            continue
        }

        Typography {
            variant = TypographyVariant.h6

            +app.name
        }

        Grid {
            container = true
            spacing = responsive(2)

            for (i in envNamesSlice) {
                val env = app.app.environments[envNames[i]]

                Grid {
                    item = true
                    xs = if (showDiffOnly) 6 else 3

                    if (env != null) {
                        DeploymentCard {
                            application = app.app
                            this.env = env
                        }
                    }
                }
            }
        }

        Divider {
            sx {
                marginTop = 5.px
            }
        }
    }
}