package games.mythical.saga.watcher.sagawatcher.frontend.component

import csstype.*
import games.mythical.saga.watcher.sagawatcher.frontend.mainScope
import games.mythical.saga.watcher.sagawatcher.frontend.util.WatcherApi
import games.mythical.saga.watcher.sagawatcher.frontend.util.xs
import games.mythical.saga.watcher.sagawatcher.model.Application
import games.mythical.saga.watcher.sagawatcher.model.SagaGitCommit
import kotlinx.coroutines.launch
import mui.icons.material.Check
import mui.icons.material.GitHub
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.*
import react.dom.html.AnchorTarget
import kotlin.js.Date

private val envs = listOf(
    EnvInfo("develop", "dev-saga", "Dev"),
    EnvInfo("main", "qa-saga", "QA"),
    EnvInfo("sandbox", "sandbox-saga", "Sandbox"),
    EnvInfo("release", "prod-saga", "Prod")
)

external interface ServiceCommitsProps : Props {
    var app: Application
}

val ServiceCommits = FC<ServiceCommitsProps> { props ->
    var loading by useState(true)
    var allCommits by useState<Map<String, List<SagaGitCommit>>>(emptyMap())
    var commitList by useState<List<CommitLine>>(emptyList())

    val onLoadMore = useCallback(allCommits) {
        loading = true
        mainScope.launch {
            val newAllCommits = allCommits.toMutableMap()

            loadAllCommits(
                props.app.githubUrl.split('/').last(),
                allCommits.mapValues { Date(it.value.last().committedAt).getTime().toLong() }
            ).forEach { (branch, commits) -> newAllCommits[branch] = newAllCommits.getOrPut(branch) { emptyList() } + commits }

            val maxCommitTs = newAllCommits.values.maxOfOrNull { Date(it.last().committedAt).getTime() } ?: 0.0

            val shaCommitMap = mutableMapOf<String, CommitLine>()

            for (env in envs) {
                val commits = newAllCommits[env.branch] ?: emptyList()
                val appEnv = props.app.environments[env.envName]
                val deployedSha = if (appEnv != null) appEnv.version.split('-')[1] else null
                for (commit in commits) {
                    val commitLine = shaCommitMap.getOrPut(commit.sha1) { CommitLine(commit) }
                    val envInfo = CommitEnvInfo(deployedSha != null && commit.sha1.startsWith(deployedSha))
                    commitLine.envInfo[env.envName] = envInfo
                }
            }

            commitList = shaCommitMap.values.toList()
                .filter { Date(it.commit.committedAt).getTime() >= maxCommitTs }
                .sortedByDescending { Date(it.commit.committedAt).getTime() }

            allCommits = newAllCommits
            loading = false
        }
    }

    useEffectOnce {
        onLoadMore()
    }

    if (!loading || commitList.isNotEmpty()) {
        Grid {
            container = true
            Grid {
                item = true
                xs = 6
                +"Commit"
            }

            for (env in envs) {
                Grid {
                    item = true
                    xs = true
                    sx {
                        textAlign = TextAlign.center
                    }

                    +env.name
                }
            }
        }

        for (line in commitList) {
            Grid {
                container = true
                sx {
                    hover {
                        backgroundColor = rgba(128, 128, 128, 0.1)
                    }
                }

                Grid {
                    container = true
                    item = true
                    xs = 6
                    sx {
                        paddingRight = 10.px
                        paddingTop = 5.px
                        paddingBottom = 5.px
                        alignItems = AlignItems.center
                        hover {
                            " .HiddenButton" {
                                visibility = Visibility.visible
                            }
                        }
                    }

                    Grid {
                        item = true
                        xs = 12

                        +line.commit.message.split('\n')[0]
                    }

                    Grid {
                        item = true
                        xs = true

                        Typography {
                            variant = TypographyVariant.subtitle2
                            +Date(line.commit.committedAt).toLocaleString()
                        }
                    }

                    Grid {
                        item = true
                        xs = "auto"
                        sx {
                            margin = Margin(0.px, 10.px)
                        }

                        Tooltip {
                            sx {
                                visibility = Visibility.hidden
                            }
                            className = ClassName("HiddenButton")
                            disableInteractive = true
                            title = ReactNode("View in GitHub")

                            Link {
                                href = line.commit.githubUrl
                                target = AnchorTarget._blank
                                rel = "noreferrer"
                                color = "inherit"

                                GitHub { }
                            }
                        }
                    }

                    Grid {
                        item = true
                        xs = "auto"
                        sx {
                            color = Color("#AAA")
                        }

                        Typography {
                            sx {
                                color = Color("#AAA")
                            }
                            variant = TypographyVariant.subtitle2
                            +line.commit.sha1.substring(0, 7)
                        }
                    }
                }

                Grid {
                    container = true
                    item = true
                    xs = 6

                    for (env in envs) {
                        val envInfo = line.envInfo[env.envName]

                        Grid {
                            container = true
                            item = true
                            xs = true
                            sx {
                                if (envInfo != null) {
                                    backgroundColor = rgba(0, 100, 0, 0.4)
                                }
                                alignItems = AlignItems.center
                            }

                            Grid {
                                item = true
                                xs = 12
                                sx {
                                    textAlign = TextAlign.center
                                }
                                if (envInfo != null && envInfo.deployed) {
                                    Tooltip {
                                        title = ReactNode("Deployed")
                                        Check {
                                            color = SvgIconColor.success
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                Grid {
                    item = true
                    xs = 12
                    Divider { }
                }
            }
        }

        if (!loading) {
            Box {
                sx {
                    textAlign = TextAlign.center
                    marginTop = 20.px
                }

                Button {
                    variant = ButtonVariant.outlined
                    onClick = { onLoadMore() }

                    +"Load more"
                }
            }
        }
    }

    if (loading) {
        Box {
            sx {
                textAlign = TextAlign.center
                marginTop = 20.px
            }
            CircularProgress { }
        }
    }
}

private suspend fun loadAllCommits(repo: String, fromTimestamp: Map<String, Long>): Map<String, List<SagaGitCommit>> {
    val commits = mutableMapOf<String, List<SagaGitCommit>>()

    for (branch in envs.map { it.branch }) {
        commits[branch] = WatcherApi.fetchCommits(repo, branch, fromTimestamp[branch])
    }

    return commits
}

private data class CommitLine(
    val commit: SagaGitCommit,
    val envInfo: MutableMap<String, CommitEnvInfo> = mutableMapOf()
)

private data class CommitEnvInfo(
    val deployed: Boolean
)

private data class EnvInfo(
    val branch: String,
    val envName: String,
    val name: String,
)