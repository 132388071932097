package games.mythical.saga.watcher.sagawatcher.frontend.component

import browser.window
import games.mythical.saga.watcher.sagawatcher.model.Application
import games.mythical.saga.watcher.sagawatcher.model.Environment
import mui.material.*
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import react.dom.aria.ariaLabel
import react.dom.html.ReactHTML

external interface DeploymentCardProps : Props {
    var application: Application
    var env: Environment
}

val DeploymentCard = FC<DeploymentCardProps> { props ->
    val env = props.env

    val hash = env.version.split("-").last()

    Card {
        CardContent {
            Typography {
                asDynamic().color = "text.secondary"
                gutterBottom = true

                +env.name
            }
            Typography {
                component = ReactHTML.div
                variant = TypographyVariant.h5

                +env.version
            }
        }
        CardActions {
            Button {
                ariaLabel = "${env.name}-${env.version}-github-link"
                size = Size.small
                onClick = {
                    window.open("${props.application.githubUrl}/commit/$hash")
                }

                +"View Commit on Github"
            }
        }
    }
}