package games.mythical.saga.watcher.sagawatcher.frontend.component

import games.mythical.saga.watcher.sagawatcher.frontend.ApplicationProps
import games.mythical.saga.watcher.sagawatcher.frontend.entity.Applications
import react.*

val ApplicationsContext = createContext<Applications>()

val ApplicationsModule = FC<ApplicationProps> { props ->
    ApplicationsContext(props.applications) {
        +props.children
    }
}