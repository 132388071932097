package games.mythical.saga.watcher.sagawatcher.frontend.entity

import games.mythical.saga.watcher.sagawatcher.frontend.AppVersionProps
import react.FC

data class Application(
    val name: String,
    val app: games.mythical.saga.watcher.sagawatcher.model.Application,
    val component: FC<AppVersionProps>,
)

typealias Applications = Iterable<Application>
